import { mapHelper } from "@/utils/common.js";

// const type = [
//   {
//     value: 2,
//     label: "社区超话",
//   },
//   // {
//   //   value: 15,
//   //   label: "居民超话",
//   // },
//   // {
//   //   value: null,
//   //   label: "全部",
//   // },
// ];

// const { map: typeMap, setOps: setTypeOps } = mapHelper.setMap(type);

const status = [
  {
    value: 1,
    label: "发布",
  },
  {
    value: 3,
    label: "取消",
  },
];

const { map: statusMap, setOps: setStatusOps } = mapHelper.setMap(status);

// 是否置顶，是否可以点赞，是否可以评论
const isTop = [
  {
    value: 0,
    label: "否",
  },
  {
    value: 1,
    label: "是",
  },
];

const { map: isTopMap, setOps: setIsTopOps } = mapHelper.setMap(isTop);

const sortField = [
  {
    value: "NEW",
    label: "最新",
  },
  {
    value: "HOT",
    label: "最热",
  },
];

const { map: sortFieldMap, setOps: sortFieldOps } = mapHelper.setMap(sortField);

export {
  statusMap,
  setStatusOps,
  isTopMap,
  setIsTopOps,
  sortFieldMap,
  sortFieldOps,
  // typeMap,
  // setTypeOps,
  // type,
};
