//获取话题列表
const getTopicListURL = `/gateway/hc-neighbour/topic/getTopicList`;
//获取话题详情
const getTopicDetailURL = `/gateway/hc-neighbour/topic/getTopicInfoById`;
//新增话题
const addTopicURL = `/gateway/hc-neighbour/topic/addTopic`;
//更新话题
const updateTopicURL = `/gateway/hc-neighbour/topic/updateTopic`;
// 删除超话接口
const deleteTopicUrl = `/gateway/hc-neighbour/topic/deleteTopic`;
// 获取话题详情
const getTopicInfoByIdURL = `/gateway/hc-neighbour/topic/getTopicInfoById`;
// //获取园区列表
// const getCommunityListUrl = `/gateway/hc-space/space/community`;
// // 发送积分
// const doTopicURL = "/gateway/hc-accumulated/dailyPerf/doTopic";
// // 审核居民超话
// const auditUrl = `/gateway/hc-neighbour/topic/audit`;

export {
  getTopicListURL,
  getTopicDetailURL,
  addTopicURL,
  updateTopicURL,
  // getCommunityListUrl,
  // // getInitInfoURL,
  // getCommentPraiseRankingListURL,
  // getTalentUserListURL,
  getTopicInfoByIdURL,
  // doTopicURL,
  deleteTopicUrl,
  // auditUrl,
};
